<template>
  <div
    id="search-page"
    class=""
  >
    <b-row class="mb-5">
      <b-col>
        <h1>{{ $t('Buscador') }}</h1>
        <h5 class="text-primary">
          {{ $t('BuscadorContenidos') }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="danger"
          @click="back()"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>

    <keep-alive>
      <component :is="search" />
    </keep-alive>
  </div>
</template>
<script>
import {
  BCol,
  BImg,
  BRow,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormInput,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCardTitle,
  BOverlay,
  BFormSelect,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckboxGroup,
  BFormSelectOption,
} from 'bootstrap-vue'
import CategorySelector from '../../common/CategorySelector.vue'
import search from './search.vue'

export default {
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
    BCol,
    BFormCheckbox,
    BModal,
    BRow,
    BFormSelectOption,
    BFormCheckboxGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
    BCardTitle,
    CategorySelector,
    BCardText,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BOverlay,
  },
  data() {
    return {
      search,
    }
  },
  methods: {
    back() {
      window.history.back()
    },
  },
}
</script>

<style scoped>
.card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

.actionsBar {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.carpeta img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
    height:  100%;
    object-fit: cover;*/
}
.card-body {
  padding: 1rem;
}

.card-body h4 {
  font-size: 1rem !important;
}

.card-header {
  padding: 1rem;
}

.card-header h4 {
  font-size: 1rem !important;
}

.card-body .actionsBar {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  /* -webkit-justify-content: flex-end;
  justify-content: flex-end; */
}

.right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
/*.folder {
  min-height: 150px;
  height: 300px;
  cursor: all-scroll;
}*/
.dropContainer {
  border: 3px dashed;
}
/*.card-width {
  width: 200px;
}*/
.list-group-item {
  transition: all 1s;
}
.active {
  border: white 1px;
}

.active {
  border: 0px;
}
#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
